import { createContext, Dispatch, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import { getGroupTreeByRanchID, getHerds } from '../services/GroupTree';
import { getAllDataObservations, getDataObservations } from '../services/DataObservations';
import { getDataObservationsMetadata, getGroupTreeMetadata } from '../services/Metadata';
import { keyClean, keyCompare } from '../helpers/dynamoKeyFunctions';
// import { forEach } from 'react-bootstrap/ElementChildren';
import { useLocalStorage } from 'usehooks-ts';
import { useAuth } from './AuthContext';
import useNestedLocalStorage from 'hooks/useNestedLocalStorage';

export type DataContext = {
    fetchGroupTreeData: () => Promise<void>;
    fetchHerds: () => Promise<void>;
    groupTreeData: GroupTree | null;
    // dataObservationData: Promise<DataObservation[] | null>;
    currentRoot: null | string;
    setCurrentRoot: (root: string | null) => void;
    selectedMU: null | ManagementUnit;
    setSelectedMU: Dispatch<null | ManagementUnit>;
    mapCenter: Coordinate;
    setMapCenter: Dispatch<Coordinate>;
    groupTreeMetadata: any;
    dataObservationsMetadata: any;
    currentBranch: null | Pasture;
    setCurrentBranch: Dispatch<Pasture | null>;
    setCurrentBranchFromName: Dispatch<string>;
    setCurrentPointLocationFromSK: (sk: string) => void;
    setCurrentPointLocation: Dispatch<SampleLocation | null>;
    currentPointLocation: SampleLocation | null;
    herds: Herd[] | null;
    sampleLocations: SampleLocation[] | null;
    splits: Split[] | null;
    groupPath: Promise<GroupPath>;
    pastures: Pasture[] | null;
    currentRanch: Ranch | null;
    managementUnits: ManagementUnit[] | null;
    herdTypes: HerdType[];
    // for Point data
    showPointData: boolean;
    setShowPointData: Dispatch<boolean>;
    ranchUserRoll: RanchUserPermission | null;
};

const DataContext = createContext<undefined | DataContext>(undefined);

const useData = () => {
    const data = useContext(DataContext);
    if (!data) {
        throw new Error('useData must be used within DataProvider');
    }
    return data;
};

type HerdType = {
    name: string;
    wseWeight: number;
    animalConversion: number;
};

const herdTypes = [
    {
        name: 'Bulls',
        wseWeight: 2160,
        animalConversion: 1.38,
    },
    {
        name: 'Cows',
        wseWeight: 1200,
        animalConversion: 1.0,
    },
    {
        name: 'Heifers',
        wseWeight: 960,
        animalConversion: 0.9,
    },
    {
        name: 'Horses',
        wseWeight: 1200,
        animalConversion: 1.0,
    },
    {
        name: 'Pairs',
        wseWeight: 1560,
        animalConversion: 1.21,
    },
    {
        name: 'Weaned',
        wseWeight: 720,
        animalConversion: 0.44,
    },
    {
        name: 'Yearlings',
        wseWeight: 960,
        animalConversion: 0.56,
    },
    {
        name: 'Sheep',
        wseWeight: 200,
        animalConversion: 0.125,
    },
    {
        name: 'Bucks',
        wseWeight: 250,
        animalConversion: 0.135,
    },
];

const DataProvider: React.FC = ({ children }: PropsWithChildren<any>) => {
    const { isAuthenticated, groupTrees, user } = useAuth();

    const [currentRoot, setCurrentRoot] = useLocalStorage<null | string>('currentRoot', null);
    const [selectedMU, setSelectedMUState] = useState<null | ManagementUnit>(null);
    const [mapCenter, setMapCenter] = useState<Coordinate>([-109.8884553909351, 46.30775883983472]);
    const [herds, setHerds] = useState<Herd[] | null>(null);
    const [currentBranch, setCurrentBranch] = useState<null | Pasture>(null);
    const [currentPointLocation, setCurrentPointLocation] = useState<null | SampleLocation>(null);
    const [groupTreeData, setGroupTreeData] = useState<null | GroupTree>(null);
    const [splits, setSplits] = useState<Split[] | null>(null);
    const [managementUnits, setManagementUnits] = useState<null | ManagementUnit[]>(null);
    const [pastures, setPastures] = useState<null | Pasture[]>(null);
    const [currentRanch, setCurrentRanch] = useState<null | Ranch>(null);
    const [sampleLocations, setSampleLocations] = useState<null | SampleLocation[]>(null);
    const [showPointData, setShowPointData] = useNestedLocalStorage('dataContext.showPointData', false);

    const [ranchUserRoll, setRanchUserRoll] = useState<RanchUserPermission | null>(null);

    useEffect(() => {
        if (!isAuthenticated) {
            // setCurrentRoot(null);
            setHerds(null);
            setSampleLocations(null);
            setSplits(null);
            setPastures(null);
            setManagementUnits(null);
            setGroupTreeData(null);
            setCurrentBranch(null);
            setCurrentPointLocation(null);
            setCurrentRanch(null);
            setRanchUserRoll(null);
            setSelectedMUState(null);
            setRanchUserRoll(null);
        }
    }, [isAuthenticated]);

    useEffect(() => {
        // console.log('change in MU: ', selectedMU);
        if (currentRanch && selectedMU && selectedMU.centerpoint) {
            // console.log('setting map center to selectedMU centerpoint: ', selectedMU.centerpoint);
            setMapCenter(selectedMU.centerpoint);
        }
    }, [selectedMU, currentRanch]);

    const groupPath = useMemo(async () => {
        let curGroupPath: GroupPath = {};
        // console.log('group path cur branch: ', currentBranch);
        if (currentBranch) {
            // const metadata = await getGroupTreeMetadata();
            // console.log('meta: ', metadata);
            // console.log('obj type: ', keyClean(currentBranch.objectType));
            const branchMetadata = getGroupTreeMetadata(currentBranch.objectType) as Metadata;
            // const branchMetadata = metadata.find((m) => keyCompare(m.name, currentBranch.objectType));
            // console.log('branchMeta: ', branchMetadata);
            if (branchMetadata) {
                const pathKeys = branchMetadata.group_path;
                const pasture = currentBranch as Pasture;
                const pathVars = pasture.groupPath!.split('#');
                // console.log('pathKeys', pathKeys);
                // console.log('pathVars', pathVars);
                pathKeys?.forEach((key, i) => {
                    let cleanKey = key.replace('<', '').replace('>', '');
                    if (cleanKey === 'name') {
                        cleanKey = keyClean(currentBranch.objectType);
                    }
                    curGroupPath[cleanKey] = pathVars[i] || undefined;
                });
            }
        }
        // console.log('curGroupPath: ', curGroupPath);
        return curGroupPath;
    }, [currentBranch]);

    const groupTreeMetadata = useMemo(async () => {
        const metadata = await getGroupTreeMetadata();
        // console.log('metadata: ', metadata);
        return metadata;
    }, []);

    const dataObservationsMetadata = useMemo(async () => {
        const metadata = getDataObservationsMetadata();
        // console.log('metadata: ', metadata);
        return metadata;
    }, []);

    // useEffect(() => {
    //     clearGroupTreeData();
    // }, [currentRoot]);

    const setSelectedMU = (mu: null | ManagementUnit) => {
        setSelectedMUState(mu);
        if (!mu) {
            window.localStorage.removeItem('selectedMU');
        } else {
            window.localStorage.setItem('selectedMU', mu?.name || '');
        }
    };

    useEffect(() => {
        // console.log('change current root: ', currentRoot);
        // console.log('groupTrees', groupTrees);

        if (!user || !isAuthenticated) {
            console.log('no user or not authenticated');
            console.log('user', user);
            console.log('isAuthenticated', isAuthenticated);
            // } else if (!groupTrees || groupTrees.length === 0) {
            // setCurrentRoot(null);
        } else if (currentRoot && groupTrees && groupTrees.includes(currentRoot)) {
            fetchGroupTreeData();
            const lsMU = window.localStorage.getItem('selectedMU');
            if (lsMU && !selectedMU) {
                const mu = managementUnits?.find((mu) => keyCompare(mu.name, lsMU));
                if (mu) setSelectedMU(mu);
            }
        } else {
            console.log('no current root');
            if (groupTrees && groupTrees.length > 0) {
                setCurrentRoot(groupTrees[0]);
            } else {
                console.log('no group trees!');
            }
        }
    }, [isAuthenticated]);

    useEffect(() => {
        if (currentRoot && isAuthenticated) {
            fetchGroupTreeData();
        }
    }, [currentRoot]);

    const clearGroupTreeData = () => {
        console.log('clearing GroupTreeData');
        setHerds(null);
        setSampleLocations(null);
        setSplits(null);
        setPastures(null);
        setManagementUnits(null);
        setGroupTreeData(null);
    };

    const fetchGroupTreeData = async () => {
        //     get groupTree data off current root
        // console.log('fetching group tree data!!');
        if (currentRoot && isAuthenticated) {
            let gtd = await getGroupTreeByRanchID(currentRoot).catch((err) => {
                console.error('Errors getting group tree by ranch ID: ', err);
            });
            // console.log('got groupTree Data in Data Context: ', gtd);

            if (gtd && gtd.length > 0) {
                // HERDS
                const herds = gtd.filter((gt: GroupTreeBranch) => keyCompare(gt.objectType, 'herd')) as Herd[];
                // console.log('herds: ', herds);
                setHerds(herds);

                // Sample Locations
                const sampleLocations = gtd.filter((gt: GroupTreeBranch) =>
                    keyCompare(gt.objectType, 'sample-location')
                ) as SampleLocation[];
                setSampleLocations(sampleLocations);

                // SPLITS
                const splits = gtd.filter((gt: GroupTreeBranch) => keyCompare(gt.objectType, 'pasture-split'));
                // console.log('splits: ', splits);
                setSplits(splits);

                // Management Units
                const mu = gtd.filter((gt: GroupTreeBranch) =>
                    keyCompare(gt.objectType, 'management-unit')
                ) as ManagementUnit[];
                // console.log('managementUnits: ', mu);
                setManagementUnits(mu);

                // Pastures
                const allPastures = gtd.filter((branch: GroupTreeBranch) => {
                    // TODO: Metadata Driven
                    return keyCompare(branch.objectType, 'Pasture');
                }) as Pasture[];
                // Active Pastures
                const activePastures = allPastures.filter((p) => p.status === 'ACTIVE') as Pasture[];
                setPastures(activePastures);

                // Ranch
                const r: Ranch = gtd.find((branch: GroupTreeBranch) => {
                    return keyCompare(branch.objectType, 'Ranch');
                });
                setCurrentRanch(r);
                // console.log('ranch', r);

                // Ranch User Roll

                if (user?.isSuperAdmin) {
                    setRanchUserRoll({ email: user.email, role: 'SUPER_ADMIN', status: 'ACTIVE' });
                } else if (r && user && user.email) {
                    const roll = r.userPermissions.find((roll) => keyCompare(roll.email, user.email));
                    if (roll) {
                        setRanchUserRoll(roll);
                    } else setRanchUserRoll(null);
                }

                setGroupTreeData(gtd);
            } else {
                console.log('no data found for group Tree Data');
                setHerds([]);
                setSampleLocations([]);
                setSplits([]);
                setPastures([]);
                setManagementUnits([]);
                setGroupTreeData([]);
            }
        }
    };

    const fetchHerds = async () => {
        if (currentRoot && isAuthenticated) {
            const herdRes = await getHerds(currentRoot);
            console.log('herdRes', herdRes);
            if (herdRes) {
                setHerds(herdRes);
            }
        }
    };

    // const mergeHeardWeights = (herds: Herd[]) => {
    //     return herds.map((herd) => {
    //         let herdType = herdTypes.find((ht) => keyCompare(ht.name, herd.name));
    //         if (!herdType) {
    //             herdType = {
    //                 name: 'DEFAULT',
    //                 animalConversion: 1,
    //                 wseWeight: 1200,
    //             };
    //         }
    //         return {
    //             ...herd,
    //             animalConversion: herdType.animalConversion,
    //             wseWeight: herdType.wseWeight,
    //         };
    //     });
    // };

    const setCurrentBranchFromName = (name: string) => {
        if (groupTreeData) {
            const branch = groupTreeData.find((gt) => keyCompare(gt.name, name)) as Pasture;
            if (branch) {
                setCurrentBranch(branch);
            } else {
                console.error('No branch found with name: ', name);
            }
        }
    };

    function setCurrentPointLocationFromSK(sk: string) {
        if (sampleLocations) {
            const loc = sampleLocations.find((sl) => keyCompare(sl.sk, sk));
            if (loc) {
                setCurrentPointLocation(loc);
            }
        }
    }

    return (
        <DataContext.Provider
            value={{
                currentRoot,
                setCurrentRoot,
                selectedMU,
                setSelectedMU,
                groupTreeData,
                fetchGroupTreeData,
                fetchHerds,
                // dataObservationData,
                mapCenter,
                setMapCenter,
                groupTreeMetadata,
                dataObservationsMetadata,
                currentBranch,
                setCurrentBranch,
                setCurrentBranchFromName,
                setCurrentPointLocationFromSK,
                setCurrentPointLocation,
                currentPointLocation,
                herds,
                sampleLocations,
                herdTypes,
                splits,
                groupPath,
                pastures,
                currentRanch,
                managementUnits,
                // Point Data things s
                showPointData,
                setShowPointData,
                ranchUserRoll,
            }}>
            {children}
        </DataContext.Provider>
    );
};

export { useData, DataProvider };
